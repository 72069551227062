<template>
    <tr class="clickable-row">
      <td><router-link target="_blank" :to="{name:'reservation_detail', params: {reservation_id: cashReport.id}}">{{cashReport.id}}</router-link></td>
      <td><router-link target="_blank" :to="{name:'reservation_detail', params: {reservation_id: cashReport.id}}"><span class="badge">{{StringsHelper.getChannel(cashReport.channel)}}</span></router-link></td>
      <td><router-link target="_blank" :to="{name:'reservation_detail', params: {reservation_id: cashReport.id}}">{{Utils.getHourStringFromISO(cashReport.date)}}</router-link></td>
      <td><router-link target="_blank" :to="{name:'reservation_detail', params: {reservation_id: cashReport.id}}">{{StringsHelper.getOfferta(cashReport)}}</router-link></td>
      <td><router-link target="_blank" :to="{name:'reservation_detail', params: {reservation_id: cashReport.id}}"> <span v-if="cashReport.creator">{{StringsHelper.getClientStringNameFromReservation(cashReport)}}</span> </router-link></td>
      <td><router-link target="_blank" :to="{name:'reservation_detail', params: {reservation_id: cashReport.id}}"> <span v-if="cashReport.document_type">{{StringsHelper.getDocumentType(cashReport.document_type)}}</span> </router-link></td>
      <td><router-link target="_blank" :to="{name:'reservation_detail', params: {reservation_id: cashReport.id}}"> {{StringsHelper.getPaymentMode(cashReport.payment_mode)}}</router-link></td>
      <td class="text-right"><router-link target="_blank" :to="{name:'reservation_detail', params: {reservation_id: cashReport.id}}">{{cashReport.price_final + " €"}}</router-link></td>
    </tr>
</template>

<script>
    
    import baseMixin from '@/common/baseMixin'
    export default {
        
        name: "CashReportRow",
        mixins: [baseMixin],
        props: {
            cashReport: {
                type: Object,
                required: true
            }
        } 
    }
</script>
