<template>
    <div>
        <table v-if="cashReports.length > 0" class="table table-hover">
            <thead>
            <tr>
                <th colspan="2">PRENOTAZIONE</th>
                <th>ORA</th>
                <th>OFFERTA</th>
                <th>CLIENTE</th>
                <th>TIPO</th>
                <th>PAGAMENTO</th>
                <th class="text-right">IMPORTO</th>
            </tr>
            </thead>
            <tbody id="cashReport-list">
            <template>
                <CashReportRow v-for='cashReport in cashReports' :cash-report="cashReport" v-bind:key ='cashReport.id'/>
            </template>

            </tbody>
        </table>
        <span v-else class="text-muted">
            Nessuna prenotazione trovata nella data odierna
        </span>
    </div>

</template>
<script>

    import CashReportRow from "./CashReportRow"
    export default {

        name: "CashReportTable",
        components:{CashReportRow},
        props: {
            cashReports: {
                type: Array,
                required: true
            }
        }
    }
</script>
